export default class CcProductTag {
  private _color: string;
  private _description: string;
  private _label: string;
  private _uniqueId: string;

  constructor(uniqueId: string, label: string, description: string, color: string) {
    this._description = description;
    this._label = label;
    this._uniqueId = uniqueId;
    this._color = color;
  }

  get color(): string {
    return this._color;
  }

  set color(color: string) {
    this._color = color;
  }

  get description(): string {
    return this._description;
  }

  set description(description: string) {
    this._description = description;
  }

  get label(): string {
    return this._label;
  }

  set label(label: string) {
    this._label = label;
  }

  get uniqueId(): string {
    return this._uniqueId;
  }

  set uniqueId(uniqueId: string) {
    this._uniqueId = uniqueId;
  }
}
