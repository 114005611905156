import { ActionTree } from 'vuex';
import StoreStateInterface from './store-state-interface';
import CcCategory from '../../domain/category/cc-category';
import CcUser from '@/main/webapp/resources/src/vue/domain/user/cc-user';
import CcOrderType from '@/main/webapp/resources/src/vue/domain/order/cc-ordertype';
import CcOrder from '@/main/webapp/resources/src/vue/domain/order/cc-order';
import CcStore from '@/main/webapp/resources/src/vue/domain/store/cc-store';
import CcProduct from '@/main/webapp/resources/src/vue/domain/product/cc-product';
import CcProductStock from '@/main/webapp/resources/src/vue/domain/productstock/cc-productstock';
import CcBannerArea from '@/main/webapp/resources/src/vue/domain/banner/cc-banner-area';
import CcBrand from '@/main/webapp/resources/src/vue/domain/brand/cc-brand';
import CcCustomer from '@/main/webapp/resources/src/vue/domain/user/cc-customer';
import CcProductTag from '@/main/webapp/resources/src/vue/domain/product/cc-product-tag';

const storeActions: ActionTree<StoreStateInterface, any> = {
	setAppKey: ({ commit }, appKey: number) => {
		return commit('setAppKeyMutation', appKey);
	},

	setCategories: ({ commit }, categories: Array<CcCategory>) => {
		return commit('setCategoriesMutation', categories);
	},

	setProducts: ({ commit }, products: Array<CcProduct>) => {
		return commit('setProductsMutation', products);
	},

	setProductStocks: ({ commit }, productStocks: Array<CcProductStock>) => {
		return commit('setProductStocksMutation', productStocks);
	},

	setPageData: ({ commit }, pageData: any) => {
		return commit('setPageDataMutation', pageData);
	},

	setUser: ({ commit }, user: CcUser) => {
		return commit('setUserMutation', user);
	},

	setOrder: ({ commit }, order: CcOrder) => {
		return commit('setOrderMutation', order);
	},

	setOrderNotes: ({ commit }, orderNotes: string) => {
		return commit('setOrderNotesMutation', orderNotes);
	},

	setOrders: ({ commit }, orders: Array<CcOrder>) => {
		return commit('setOrdersMutation', orders);
	},

	setOrdersPageData: ({ commit }, ordersPageData: any) => {
		return commit('setOrdersPageDataMutation', ordersPageData);
	},

	setOrderType: ({ state, commit }, orderType: CcOrderType) => {
		state.order.type = orderType;
		return commit('setOrderMutation', state.order);
	},

	setStores: ({ commit }, stores: Array<CcStore>) => {
		return commit('setStoreMutation', stores);
	},

	updateOrderQuantity: ({ state, commit }, payload: any) => {
		const index: number = state.order.items.findIndex(
			(product: CcProductStock) =>
				product.product.code === payload.productStock.product.code
		);
		if (index > -1) {
			state.order.items[index].quantity = payload.quantity;
		} else {
			payload.productStock.quantity = payload.quantity;
			state.order.items.push(payload.productStock);
		}
		return commit('setOrderMutation', state.order);
	},

	setSearchParams: ({ commit }, searchParams: any) => {
		return commit('setSearchParamsMutation', searchParams);
	},

	setSearchCategoryPaths: ({ state, commit }, categoryPaths: string) => {
		state.searchParams.categoryPaths = categoryPaths;
		return commit('setSearchParamsMutation', state.searchParams);
	},

	setSearchDescriptionLike: ({ state, commit }, descriptionLike: string) => {
		state.searchParams.descriptionLike = descriptionLike;
		return commit('setSearchParamsMutation', state.searchParams);
	},

	setSearchAttribute: ({ state, commit }, attribute: Array<any>) => {
		state.searchParams.attribute = attribute;
		return commit('setSearchParamsMutation', state.searchParams);
	},

	setSearchTags: ({ state, commit }, tags: Array<CcProductTag>) => {
		state.searchParams.productTags = tags;
		return commit('setSearchParamsMutation', state.searchParams);
	},

	setSearchCustomSort: ({ state, commit }, customSort: string) => {
		state.searchParams.customSort = customSort;
		return commit('setSearchParamsMutation', state.searchParams);
	},

	setSearchBrands: ({ state, commit }, brands: Array<any>) => {
		state.searchParams.brands = brands;
		return commit('setSearchParamsMutation', state.searchParams);
	},

	readyToStart: ({ commit }, readyToStart: boolean) => {
		return commit('readyToStartMutation', readyToStart);
	},

	setBannerAreaHome: ({ commit }, bannerArea: CcBannerArea) => {
		return commit('setBannerAreaHomeMutation', bannerArea);
	},

	setBannerAreaHomePrivate: ({ commit }, bannerArea: CcBannerArea) => {
		return commit('setBannerAreaHomePrivateMutation', bannerArea);
	},

	setBannerAreaProducts: ({ commit }, bannerArea: CcBannerArea) => {
		return commit('setBannerAreaProductsMutation', bannerArea);
	},

	setBannerAreaRegistration: ({ commit }, bannerArea: CcBannerArea) => {
		return commit('setBannerAreaRegistrationMutation', bannerArea);
	},

	setSearchAids: ({ commit }, searchAids: Array<any>) => {
		return commit('setSearchAidsMutation', searchAids);
	},

	setProvinces: ({ commit }, provinces: Array<any>) => {
		return commit('setProvincesMutation', provinces);
	},

	setBrands: ({ commit }, brands: Array<any>) => {
		return commit('setBrandsMutation', brands);
	},

	setIsStoreOptionsShowed: ({ commit }, isStoreOptionsShowed: boolean) => {
		return commit('setIsStoreOptionsShowedMutation', isStoreOptionsShowed);
	},

	setDisabledDates: ({ commit }, disabledDates: Array<Date>) => {
		return commit('setDisabledDatesMutation', disabledDates);
	},

	setUrlPreLogin: ({ commit }, urlPreLogin: string) => {
		return commit('setUrlPreLoginMutation', urlPreLogin);
	},

	setCustomerShippingFormData: ({ state, commit }, customerShippingFormData: any) => {
		state.customerShippingForm.shippingData = customerShippingFormData;
		return commit('setCustomerShippingFormMutation', state.customerShippingForm);
	},

	setCustomerShippingFormErrors: ({ state, commit }, customerShippingFormErrors: any) => {
		state.customerShippingForm.errors = customerShippingFormErrors;
		return commit('setCustomerShippingFormMutation', state.customerShippingForm);
	},

	setSecondaryCustomers: ({ commit }, secondaryCustomers: Array<CcCustomer>) => {
		return commit('setSecondaryCustomersMutation', secondaryCustomers);
	},

	setSecondaryCustomersPageData: ({ commit }, secondaryCustomersPageData: any) => {
		return commit('setSecondaryCustomersPageDataMutation', secondaryCustomersPageData);
	},

	setProductTags: ({ commit }, productTags: Array<CcProductTag>) => {
		return commit('setProductTagsMutation', productTags);
	},

	setCollectTimeOptions: ({ commit }, collectTimeOptions: Array<any>) => {
		return commit('setCollectTimeOptionsMutation', collectTimeOptions);
	}
};
export default storeActions;
